const BoxListDescriptif = ({ children, border= true, widthFull = true }) => {
    return (
        <div className={`mb-4 px-4 ${border && "border border-slate-200"} ${widthFull ? "w-full rounded-2xl": "rounded-md"} `}>
            <ul className="flex flex-col divide-y divide-slate-200">
                { children }
            </ul>
        </div>
    );
};

export default BoxListDescriptif;