import AxiosService from "./AxiosService";


// const urlNetworkPPA = 'api/network_p_p_as';
const urlNetworkPPA = 'api/network_ppa';
const urlPPATabs = 'api/tabs';
const urlPPAForms = 'api/forms';
const urlPPAPreloadServices = 'api/service_types/list/network';
const urlPPA = 'api/ppa';
const urlSavePPA = 'api/ppa/save';
const urlTokenPPA = 'api/ppa/token';


class PPAService {

    getPPANetworkFromCode(code) {
        return AxiosService.getWithoutToken(urlNetworkPPA + "/network/" + code, "jsonld");
    }

    getPPANetworkFromPPANetworkCode(ppatypecode) {
        return AxiosService.getWithoutToken(urlNetworkPPA +  "/code/" + ppatypecode, "jsonld");
    }

    getPPANetworkById(id) {
        return AxiosService.getWithoutToken(urlNetworkPPA + "/" + id, "jsonld");
    }

    getPPAPreloadServices(id) {
        return AxiosService.getWithoutToken(urlPPAPreloadServices + "/" + id + "?itemsPerPage=500", "jsonld");
        // return AxiosService.getWithoutToken(urlPPAPreloadServices + "/" + id, "jsonld");
    }

    // Save PPA
    savePPA(data) {
        return AxiosService.postWithoutToken(urlSavePPA, data);
    }

    // Update PPA
    updatePPA(id, data) {
        return AxiosService.putWithoutToken(urlSavePPA + "/" + id, data);
    }

    // Submit PPA WITH previous Save
    finishPPA(id, data) {
        return AxiosService.putWithoutToken(urlPPA + "/" + id, data);
    }

    // Submit PPA WITHOUT previous Save
    submitPPA(data) {
        return AxiosService.postWithoutToken(urlPPA, data);
    }

    // Get Token to retrieve datas after Save
    getSavedPPAFromToken(token) {
        return AxiosService.getWithoutToken(urlTokenPPA + "/" + token, "jsonld");
    }

    // PPA CONFIG
    getPPAConfigByNetworkId(id, token) {
        return AxiosService.getWithToken(urlNetworkPPA + "/config/network/" + id, token, "jsonld");
    }

    getPPAConfigById(id, token) {
        return AxiosService.getWithToken(urlNetworkPPA + "/config/" + id, token, "jsonld");
    }

    postPPA(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(urlNetworkPPA, data, "jsonld");
    }

    putPPA(data, id, token) {
        return AxiosService.putWithToken(urlNetworkPPA + "/" + id, data, token, "jsonld");
    }
    deletePPA(id, token) {
        return AxiosService.deleteWithToken(urlNetworkPPA + "/" + id, token, "jsonld");
    }
}

export default PPAService;