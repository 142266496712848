import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

export const PpaChoiceStepper = ({ activeStep, handleNext, handleBack, networkId }) => {

    const steps = [
        {
            id: 0,
            func: handleBack,
            title:"Network",
        },
        {
            id: 1,
            func: handleNext,
            title:"Type of ProjectProposal submission"
        }
    ]

    const handleClick = (stepIndex) => {
        if (activeStep === 0) {
            stepIndex === 1 && handleNext();
        } else {
            stepIndex === 0 && handleBack();
        }
    }

    return (
        <Stepper activeStep={activeStep} alternativeLabel className="ppa-choice-stepper w-full py-4 mt-4 lg:mt-6 mb-2 lg:mb-5">
            { steps.map((step, index) => (
                <Step key={step.id}
                      onClick={() => (networkId) && handleClick(index)}
                >
                    <StepLabel sx={{
                        ...networkId && ({
                            cursor: 'pointer',
                            "&.Mui-disabled": {
                                cursor: "pointer",
                            },
                        }),
                    }}
                    >
                        <div className={`name w-full mb-[20px] lg:mb-0 ${activeStep === index ? "text-base font-bold" : 'text-sm'}`}>
                            {step.title}
                        </div>
                    </StepLabel>
                </Step>
                ))}
        </Stepper>
    )
}