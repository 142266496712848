import {FormNetwork} from "../../form/FormNetwork";
import {useEffect, useState} from "react";

export const SettingBox = ({data, setActiveMenu, increment, setIncrement}) => {

    const [isUpdated, setIsUpdated] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(null);

    useEffect(() => {
        if (isUpdated) {
            setIncrement(increment + 1);
            setActiveMenu(1);
        }
    }, [isUpdated]);
    return (
        <div className="scrollNone wrapper py-10 flex flex-col items-start h-full relative overflow-y-auto">
            <FormNetwork
                method={"UPDATE"}
                network={data}
                setIsSubmitting={setIsSubmitting}
                setIsUpdated={setIsUpdated}
            />

        </div>
    )
}