import useFetchPpaConfigByNetworkId from "../hooks/useFetchPpaConfigByNetworkId";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    FiCheckCircle,
    FiEdit2,
    FiEye,
    FiEyeOff,
    FiLock,
    FiUnlock,
} from 'react-icons/fi'
import {DrawerTemporary} from "../../../components/drawer/DrawerTemporary";
import FormNetworkPPA from "./form/FormNetworkPPA";
import {truncateTextLetter} from "../../../utils/truncateText";
import Tooltip from '@mui/material/Tooltip'

const ListPPAByNetwork = ( { activeIdPpa, setActiveIdToPpa, setSubmitting, submitting }) => {

    const [idFormActive, setIdFormActive] = useState(null);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const [backCover, setBackCover] = useState("#2d7ef5");

    const {id} = useParams();

    const { data , isLoading, error, fetchPpaConfigByNetworkId} = useFetchPpaConfigByNetworkId(id);

    useEffect(() => {
        setSubmitting(false);
    }, [data]);

    useEffect(() => {
        if (!submitting) return;
        fetchPpaConfigByNetworkId();
    }, [submitting]);

    useEffect(() => {
        if (data && data.length > 0 && activeIdPpa === null) {
            setActiveIdToPpa(data[0].id);  // Set the first item's ID as the initial value
        }
    }, [data, activeIdPpa, setActiveIdToPpa]);


    const handleClick = (id) => {
        setIdFormActive(id);
        setActiveIdToPpa(id);
    }

    const onDrawerClose = () => {
        setIsOpenDrawer(false);
        setSubmitting(false);
    };

    return (
        <>
            <ul className={`w-full flex flex-col gap-0 items-center`}>
                {data?.map((item) =>
                    <li
                        key={item.id}
                        className={`relative w-full cursor-pointer group transition ease-in-out delay-75 duration-300 flex items-center justify-between min-h-12 gap-2 ${activeIdPpa === item.id ? "text-blue-700 bg-blue-50" : "text-slate-500"}`}
                        onClick={() => handleClick(item.id)}
                    >
                        <div className="w-1/2 grow px-6 flex gap-2">
                            <p className="text-sm" title={item.name}>{ truncateTextLetter(item.name, 25)}</p>
                        </div>

                        <div className="w-1/2">
                            <ul className="flex items-center gap-2">
                                <Tooltip title={item.isVisible ? 'Visible' : 'Hidden'}>
                                    <li className={`text-md ${item.isVisible ? 'text-green-700' : 'text-slate-700'}`}>
                                        {item.isVisible ? <FiEye /> : <FiEyeOff />}
                                    </li>
                                </Tooltip>
                                <Tooltip title={item.isLocked ? 'Locked' : 'Unlocked'}>
                                    <li className={`text-md ${item.isLocked ? 'text-slate-700' : 'text-green-700'}`}>
                                        {item.isLocked ? <FiLock /> : <FiUnlock />}
                                    </li>
                                </Tooltip>
                                {item.isDefault &&
                                    <Tooltip title="Default">
                                        <li className={`text-md text-green-700`}>
                                            <FiCheckCircle />
                                        </li>
                                    </Tooltip>
                                }
                            </ul>
                        </div>
                        {activeIdPpa === item.id &&
                            <button
                                title="update"
                                className={`absolute top-1/2 right-2 transform -translate-y-1/2 z-10 scale-0 w-0 group-hover:scale-100 group-hover:w-8 group transition-all duration-75 ease-in-out text-xs text-slate-500 h-8 rounded-full border border-slate-500`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsOpenDrawer(true)
                                }}
                            >
                                <span
                                    className="w-full h-full flex items-center justify-center text-xs"><FiEdit2/></span>
                            </button>
                    }
                    </li>
                )}
            </ul>
            <DrawerTemporary
                isOpenDrawer={isOpenDrawer}
                setIsOpenDrawer={setIsOpenDrawer}
                setSubmitting={setSubmitting}
                onDrawerClose={onDrawerClose}
                idSelected={idFormActive}
                content={<FormNetworkPPA data={data} idSelect={activeIdPpa} method="PUT" />}
                backCover={backCover}
                titleDrawerUpdate="Updated Network PPA"
                method="UPDATE"
            />
        </>
    )
};

export default ListPPAByNetwork;
