import {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import InputstypeServices from "../services/inputstypeServices";

export const useFetchInputTypes = (networkId, featureType) => {
    const [inputTypes, setInputTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const token = useSelector(state => state.login.user?.token) ?? null;

    useEffect(() => {
        fetchAllInputType().then();
    }, []);

    async function fetchAllInputType() {

        try {
            const inputTypeService = new InputstypeServices();

            let res;

            let featureCode = '';

            switch (featureType) {
                case 'installation':
                    featureCode = 'installation_description';
                    break;
                case 'service':
                    featureCode = 'service_description';
                    break;
                case 'ppa':
                    featureCode = 'ppa_description';
                    break;
                case 'resource':
                    featureCode = 'resource';
                    break;
                case 'service_type':
                    featureCode = 'service_type';
                    break;
                default:
                    featureCode = 'installation_description';
            }

            res = await inputTypeService.getInputTypesByFeatureCode(featureCode, token);

            setInputTypes(res["hydra:member"]);
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }

    return {inputTypes, isLoading, error};
}

export const useFetchInputTypeByCode = (code) => {
    const [inputTypeByCode, setInputTypeByCode] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const token = useSelector(state => state.login.user?.token) ?? null;

    useEffect(() => {
        fetchOneInputType();
    }, []);

    async function fetchOneInputType() {

        try {
            const inputTypeService = new InputstypeServices();
            const res = await inputTypeService.GetInputTypeByCode(code, token);

            setInputTypeByCode(res["hydra:member"][0] ?? null);
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }

    return {inputTypeByCode, isLoading, error};
}