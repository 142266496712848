import {useEffect, useState} from 'react';
import PPAService from "../../../services/PPAService";
import {useSelector} from "react-redux";

const useFetchPpaConfigByNetworkId = (networkId) => {
    const [ data, setData ] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);


    const token = useSelector((state) => state.login?.user?.token);

    useEffect(() => {
        fetchPpaConfigByNetworkId();
    }, [token]);

    const fetchPpaConfigByNetworkId = async () => {
        try {
            const ppaService = new PPAService()
            const res = await ppaService.getPPAConfigByNetworkId(networkId, token);

            if (!res) throw  new Error(`Project proposal not found`);

            setData(res["hydra:member"]);
            setIsLoading(false);

        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }
    return { data, isLoading, error, fetchPpaConfigByNetworkId }
};

export default useFetchPpaConfigByNetworkId;
