import AxiosService from "./AxiosService";

// Installation
const urlInfrastructureDescriptionsPerInfra = 'api/infrastructures/descriptions'

// Service
const urlServiceDescriptionsPerService = 'api/service_types/descriptions'


class DescriptionService {

    // Installation
    getInfrastructureDescriptionsPerInfra(id, token){
        return AxiosService.getWithToken(urlInfrastructureDescriptionsPerInfra + '/' + id, token, 'jsonld');
    }

    putInfrastructureDescription(idInfra, data, token) {
        return AxiosService.putWithToken(urlInfrastructureDescriptionsPerInfra + "/" + idInfra, data, token, 'jsonld')
    }

    // Service
    getServiceDescriptionsPerService(id, token){
        return AxiosService.getWithToken(urlServiceDescriptionsPerService + '/' + id, token, 'jsonld');
    }

    putServiceDescription(idService, data, token) {
        return AxiosService.putWithToken(urlServiceDescriptionsPerService + "/" + idService, data, token, 'jsonld')
    }

}

export default DescriptionService;