import { useEffect, useState } from 'react';
import {useSelector} from "react-redux";
import PPAService from "../../../services/PPAService";
const useFetchPpaConfigById = (id) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const token = useSelector((state) => state.login?.user?.token);

    const fetchPpaConfigById = async () => {

        setIsLoading(true);
        setError(null);

        try {
            const ppaService = new PPAService();
            const res = await ppaService.getPPAConfigById(id, token);

            if (!res) throw new Error(`Project proposal not found`);
            setData(res);
        } catch (error) {
            console.error(error);
            setError(error.message || 'Failed to fetch data');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!id) return;
        fetchPpaConfigById();
    }, [id]); // Appelle la fonction uniquement lorsque `id` change

    return { data, isLoading, error, fetchPpaConfigById };
};

export default useFetchPpaConfigById;
