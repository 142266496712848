import useFetchPpaConfigById from "../../../../ppa/hooks/useFetchPpaConfigById";
import {useState} from "react";
import ListPPAByNetwork from "../../../../ppa/components/ListPPAByNetwork";
import {CompCustom} from "../../CompCustom";
import {useParams} from "react-router-dom";
import CustomMessage from "../../../../../components/message/CustomMessage";
import {FiChevronRight, FiPlus} from "react-icons/fi";
import FormNetworkPPA from "../../../../ppa/components/form/FormNetworkPPA";
import {motion} from "framer-motion";

export const ProjectProposalBox = ({filters, networkId, setLengthofTabFilters}) => {
    const { id } = useParams();

    const [activeIdToPpa, setActiveIdToPpa] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [activeMenuListPpa, setActiveMenuListPpa] = useState(true);

    const { data, isLoading, fetchPpaConfigById } = useFetchPpaConfigById(activeIdToPpa);

    const handleOpenForm = () => {
        setIsCreate(!isCreate);
    }

    const handleMenuPpa = async () => {
        setActiveMenuListPpa(!activeMenuListPpa);
    }
;
    return (
        <div className="flex w-full gap-8 items-start h-full">
            <motion.div className={`relative flex flex-col gap-0 h-screen bg-white border ${ activeMenuListPpa ? "w-96" : "w-0"} transition-all duration-200 ease-in-out`}>
                {
                    activeMenuListPpa &&
                    <>
                        <ListPPAByNetwork
                            activeIdPpa={activeIdToPpa}
                            setActiveIdToPpa={setActiveIdToPpa}
                            myData={data}
                            submitting={submitting}
                            setSubmitting={setSubmitting}
                        />

                        <div className="relative order-first">
                            <button
                                className={`w-full h-10 rounded-sm flex items-center gap-4 justify-center text-xl ${isCreate ? "bg-red-500 text-slate-50 border-none" : "bg-blue-500 text-slate-50"}`}
                                onClick={handleOpenForm}
                            >
                                Create new <span className={` transition ease-in border border-slate-200 rounded-full h-6 w-6 flex items-center justify-center text-sm ${isCreate ? "rotate-45" : "rotate-0"}`}><FiPlus/></span>
                            </button>
                            {isCreate &&
                                <FormNetworkPPA
                                    networkId={networkId}
                                    isCreate={isCreate}
                                    setIsCreate={setIsCreate}
                                    method="POST"
                                    setSubmitting={setSubmitting}
                                    submitting={submitting}
                                    fetchPpaConfigById={fetchPpaConfigById}
                                />
                            }
                        </div>
                    </>
                }

                <button
                    title="Toggle navigation"
                    onClick={handleMenuPpa}
                    className={`${!activeMenuListPpa ? "rounded-br-full" : "rounded-br-full"}  hover:bg-slate-200 -right-8 w-8 h-10 ease-in duration-75 transition absolute transform top-0 flex items-center justify-center bg-slate-100 border border-slate-300 z-10`}
                >
                    <span className={`${!activeMenuListPpa ? "rotate-0" : "rotate-180"} text-slate-700`}><FiChevronRight/></span>
                </button>
            </motion.div>

            <div className="grow h-full w-full pt-10 overflow-x-auto whitespace-no-wrap">
                {data.length <= 0 ?
                    <CustomMessage content="Select ppa description or create new ppa description."/>
                    :
                    <CompCustom
                        id={id}
                        networkId={networkId}
                        filters={filters}
                        type="ppa"
                        setLengthofTabFilters={setLengthofTabFilters}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchPpaConfigById}
                    />
                }
            </div>
        </div>
    )
}