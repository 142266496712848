import React, {useContext, useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {Input} from "../../../../components/form/Input";
import {motion} from "framer-motion";
import {MyEditor} from "../../../../components/editor/MyEditor";
import { FiCheckCircle, FiEye, FiLock, FiTrash } from 'react-icons/fi'
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import PPAService from "../../../../services/PPAService";
import {toast} from "sonner";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {DrawerTemporaryContext} from "../../../../components/drawer/DrawerTemporary";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const FormNetworkPPA = ({ networkId, isCreate= false, idSelect, data, setIsCreate, method, setSubmitting}) => {
    const context = useContext(DrawerTemporaryContext);
    const [openConfirmationForm, setOpenConfirmationForm] = useState(false);
    const myData = data?.find(item => item.id === idSelect )

    // User token
    const user = useSelector(selectUser);
    const token = user.token;

    const {
        control,
        register,
        setValue,
        formState: {errors},
        watch,
        handleSubmit,
    } = useForm({
        defaultValues: {
            name: "",
            code: "",
            description: "",
            isDefault:false,
            isLocked: false,
            isVisible:true,
            network: networkId ? `api/networks/${networkId}` : null,
            lockMessage:null
        }
    })

    useEffect(() => {
        if (myData && !isCreate) {
            setValue("name", myData.name);
            setValue("code", myData.code);
            setValue("description", myData.description);
            setValue("isDefault", myData.isDefault);
            setValue("isLocked", myData.isLocked);
            setValue("isVisible", myData.isVisible);
            setValue("lockMessage", myData.lockMessage);
            setValue("network", `api/networks/${myData.network.id}`);
        }

    }, [myData, isCreate]);

    const ppaService = new PPAService()
    let toastMessage;

    switch (method) {
        case "POST":
            toastMessage = "Created new"
            break;
        case "PUT":
            toastMessage = "save"
            break;
        default:
            toastMessage = ""
    }

    const onSubmit = async (data) => {

        try {
            let res = null;

            if (method === "POST") {
                res = await ppaService.postPPA(data, token);

                if (!res) throw new Error("Error creating network PPA");

                setSubmitting(true);
                setIsCreate(false);
                return toast.success(`Successfully Creating network PPA`);
            } else if (method === "PUT") {
                res = await ppaService.putPPA(data, myData.id, token);

                if (!res) throw new Error("Error creating network PPA");

                context.setSubmitting(true);
                context.setIsOpenDrawer(false);

                return toast.success(`Successfully updated network PPA`);
            }


        } catch (error) {
            console.error(error);
            return toast.error("Something went wrong");
        }
    }

    const handleEditorChange = (content) => {
        setValue("description", content);
    };
    const handleChangeLockMessage = (content) => {
        setValue("lockMessage", content);
    };

    const handleDeletePpa = async (id) => {

        if (!id) return toast.warning("Invalid ID: Unable to delete. Please try again.");

        try {
            await ppaService.deletePPA(id, token);

            context.setSubmitting(true);
            context.setIsOpenDrawer(false);
            return toast.success("Successfully deleted network PPA");
        } catch (error) {
            console.error(error);
            context.setSubmitting(true);
            return toast.error("Something went wrong while deleting network PPA!");
        }
    }

    return (

        <motion.div
            className={`${!isCreate ? "flex flex-col gap-4 pt-4" : "z-20 absolute -right-96 min-h-32 w-96 bg-white border border-slate-200 rounded-sm p-4 top-0 flex gap-4 flex-col" }`}
            initial={isCreate ? { opacity: 0, translateX: -100 } : false}
            animate={isCreate ? { opacity: 1, translateX: 1 } : false}
            exit={isCreate ? { opacity: 0, translateX: 0 } : false}
            transition={isCreate ? { type: "spring", duration: .3, ease: "linear" } : {}}
        >

            {!isCreate &&
                <div>
                    <p className="flex flex-col gap-2 text-slate-700">Description
                        <span className="text-slate-700 text-sm border border-slate-200 rounded-sm bg-slate-100 p-4">
                        This page allows you to update your Preposal project with new information and view
                        the list of existing entries, ensuring that all your data is current and easily
                        accessible.
                    </span>
                    </p>
                </div>
            }

            <div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={`flex flex-col gap-4 w-full`}
                >
                    <Input
                        register={register}
                        watch={watch}
                        errors={errors}
                        type="text"
                        name="name"
                        label="Name"
                        id="name"
                        htmlFor="name"
                        placeholder="Enter the Name"
                        required={true}
                        autofocus
                    />
                    <Input
                        register={register}
                        watch={watch}
                        errors={errors}
                        type="text"
                        name="code"
                        label="Code"
                        id="code"
                        htmlFor="code"
                        placeholder="Enter the Code"
                        required={true}
                    />

                    <div className="flex flex-col gap-2">
                        <label htmlFor="description" className="block text-sm text-slate-500">Description</label>
                            <Controller
                                control={control}
                                name="description"
                                id="description"
                                render={({field}) => (
                                    <MyEditor
                                        initialvalue={watch("description")}
                                        setEditorData={handleEditorChange}
                                    />
                                )}
                            />
                    </div>

                    {!isCreate &&
                        <div className={`flex flex-col gap-2`}>
                            <p className={`text-md text-slate-700`}>Custom message when Ppa are closed</p>
                            <Controller
                                control={control}
                                name="lockMessage"
                                id="lockMessage"
                                render={({field}) => (
                                    <MyEditor
                                        initialvalue={watch("lockMessage")}
                                        setEditorData={handleChangeLockMessage}
                                    />
                                )}
                            />
                        </div>
                    }
                    {!isCreate &&
                        <ul className={`border border-slate-200 rounded-2xl p-4 flex flex-col gap-4`}>

                            <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                <div className="w-auto grow">
                                    <label htmlFor="isDefault" className="flex items-center gap-1"> <span
                                        className="text-xs"><FiCheckCircle /></span>
                                        Default element
                                    </label>
                                </div>
                                <div className="w-auto text-blue-500 flex items-center gap-2">
                                    <label
                                        htmlFor="isDefault"
                                        className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch("isDefault") ? "text-green-400 bg-green-100 border-green-300" : "text-orange-400 bg-orange-100 border-orange-300"}`}
                                    >
                                        <input
                                            type="checkbox"
                                            id="isDefault"
                                            value={data.isDefault}
                                            {...register("isDefault")}
                                            className="sr-only"
                                        />

                                        <span
                                            className={`${watch("isDefault") ? "right-0 border-green-400" : "border-orange-400 left-0"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                    </label>
                                </div>
                            </li>
                            <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                <div className="w-auto grow">
                                    <label htmlFor="isLocked" className="flex items-center gap-1"> <span
                                        className="text-xs"><FiLock/></span>
                                        Locked element
                                    </label>
                                </div>
                                <div className="w-auto text-blue-500 flex items-center gap-2">
                                    <label
                                        htmlFor="isLocked"
                                        className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch("isLocked") ? "text-green-400 bg-green-100 border-green-300" : "text-orange-400 bg-orange-100 border-orange-300"}`}
                                    >
                                        <input
                                            type="checkbox"
                                            id="isLocked"
                                            value={data.isLocked}
                                            {...register("isLocked")}
                                            className="sr-only"
                                        />

                                        <span
                                            className={`${watch("isLocked") ? "right-0 border-green-400" : "border-orange-400 left-0"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                    </label>
                                </div>
                            </li>
                            <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                <div className="w-auto grow">
                                    <label htmlFor="isVisible" className="flex items-center gap-1"> <span
                                        className="text-xs"><FiEye/></span>
                                        Visible element
                                    </label>
                                </div>
                                <div className="w-auto text-blue-500 flex items-center gap-2">
                                    <label
                                        htmlFor="isVisible"
                                        className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch("isVisible") ? "text-green-400 bg-green-100 border-green-300" : "text-orange-400 bg-orange-100 border-orange-300"}`}
                                    >
                                        <input
                                            type="checkbox"
                                            id="isVisible"
                                            value={data.isVisible}
                                            {...register("isVisible")}
                                            className="sr-only"
                                        />

                                        <span
                                            className={`${watch("isVisible") ? "right-0 border-green-400" : "border-orange-400 left-0"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    }
                    <div className="flex items-center gap-4 justify-between">
                        <button
                            title={!isCreate ? "Create new Presposal project submition" : "Update preposal project submission"}
                            type="submit"
                            className="bg-blue-400 border border-blue-500 hover:bg-blue-500 transition ease-linear text-slate-50 font-medium rounded-full p-2 w-20"
                        >{!isCreate ? "Save" : "Create"}
                        </button>
                        {!isCreate &&
                            <button
                                title="Delete form"
                                type="button"
                                className="hover:border-red-500 hover:bg-red-500 border-red-400 bg-red-400 text-slate-50 rounded-full w-8 h-8 flex items-center justify-center transition ease-linear border"
                                onClick={() => setOpenConfirmationForm(true)}
                            ><FiTrash/>
                            </button>
                        }

                        <ConfirmDialog
                            isOpen={openConfirmationForm}
                            onClose={() => setOpenConfirmationForm(false)}
                            setIsOpen={setOpenConfirmationForm}
                            onAgree={() => handleDeletePpa(myData.id)}
                        />
                    </div>
                </form>
            </div>
        </motion.div>
    );
};

export default FormNetworkPPA;
