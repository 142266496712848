import {useEffect, useState} from "react";

import DescriptionService from "../../../services/DescriptionService";
import ResourceService from '../../resource/services/Resource'
import InfrastructureService from '../../installation/service/InfrastructureService'
const descriptionService = new DescriptionService();
const resourceService = new ResourceService();


export const useFetchDescriptions = (descriptionType, elementId, userToken) => {

    // Tabs Management
    const [isDescriptionsDataLoading, setIsDescriptionsDataLoading] = useState(false);
    const [descriptionsData, setDescriptionsData] = useState([]);

    const [descriptionSubmitted, setDescriptionSubmitted] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {

            // if(typeId) {
            if(elementId || descriptionSubmitted) {
                async function handleGetDescriptions(){
                    try {
                        // setIsDescriptionsDataLoading(true);
                        !descriptionSubmitted && setIsDescriptionsDataLoading(true);

                        let response;

                        if (descriptionType === 'service') {
                            response = await descriptionService.getServiceDescriptionsPerService(elementId, userToken);
                        }

                        if (descriptionType === 'installation') {
                            response = await descriptionService.getInfrastructureDescriptionsPerInfra(elementId, userToken);
                        }

                        if (descriptionType === 'resource') {
                            response = await resourceService.getResourceInfos(elementId, userToken);
                        }

                        setDescriptionsData(response)

                        setIsDescriptionsDataLoading(false);

                        setDescriptionSubmitted(false);

                    } catch (error) {
                        console.error(error);
                        setIsDescriptionsDataLoading(false);
                    }
                }
                handleGetDescriptions().then();
            }

        }, 200)

        return () => {
            clearTimeout(timer);
        };
    // }, [typeId]);
    }, [elementId, descriptionSubmitted]);

    // return { isDescriptionsDataLoading, descriptionsData };
    return { isDescriptionsDataLoading, descriptionsData, setDescriptionSubmitted };
}


export const useFetchInfrastructureChild = (installationId, userToken) => {
    const [listInfrastructureChild, setListInfrastructureChild] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchInfrastructureChild() {

            try {
                const infraService =  new InfrastructureService();
                const res = await infraService.getInfrastructureChild(installationId, userToken);
                setListInfrastructureChild(res['hydra:member']);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        }

        fetchInfrastructureChild().then();

    }, [installationId]);

    return { listInfrastructureChild, isLoading, error };
}