import { Fragment } from 'react'
import { Grid } from '@mui/material'
import HeadingDescriptif from '../catalog/components/HeadingDescriptif'
import { Tables } from '../../components/tables/Tables'
import BoxListDescriptif from '../catalog/components/BoxListDescriptif'
import ItemDescriptif from '../catalog/components/ItemDescriptif'

export const AdditionalFields = ({ additionalFields }) => {

    const handleFormMultiple = (form) => {

        let inputValuesAndKeyLinks = []

        for (let input of form.listInputs) {

            input.listInputValues.map((iv) => {
                inputValuesAndKeyLinks.push({
                    [input.name]: iv.listDisplayValue.join(', '),
                    keyLink: iv.keyLink,
                })
            })

        }

        const inputValuesGroupedByKeyLink = Object.groupBy(inputValuesAndKeyLinks, ({ keyLink }) => keyLink)

        const inputValuesReduced = Object.values(inputValuesGroupedByKeyLink).map((item) => (
            item.reduce((result, current) => {
                return Object.assign(result, current)
            }, {})
        ))

        inputValuesReduced.forEach((element) => delete element.keyLink)

        return inputValuesReduced
    }

    return (
        additionalFields.map((tab, index) => (
            <Fragment key={'tab-' + index}>
                <HeadingDescriptif title={tab.name} />
                <Grid container rowSpacing={1} columnSpacing={{ xs: 2, lg: 4 }} sx={{ margin: '0' }}>
                    {tab.listForms.map((form, indexForm) => (
                        <Grid item sx={{ overflowY: 'auto', padding: '0' }} key={'form-' + indexForm} xs={12}
                              lg={form?.listInputs?.length < 2 ? 6 : 12}>
                            <h4 className={`lg:text-left text-slate-600 rounded-sm my-3 w-full overflow-hidden whitespace-nowrap text-ellipsis`}
                                title={form.name}
                            >
                                {form.name}
                            </h4>
                            {form.typeOfForm !== null ? (
                                <Tables tab={handleFormMultiple(form)} />
                            ) : (
                                <>
                                    {form.listInputs.map((input, indexInput) => (
                                        <BoxListDescriptif key={'input-' + indexInput} widthFull={false}>
                                            <ItemDescriptif title={input.name}
                                                            inputType={input.inputType}
                                                            dataTitle={input.name}
                                                            dataInfo={input.listInputValues.map((iv) => (
                                                                iv.listDisplayValue.join(', ')
                                                            ))}
                                            />
                                        </BoxListDescriptif>
                                    ))}
                                </>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </Fragment>
        ))
    )
}