import { useEffect, useState } from 'react';
import {FiChevronLeft, FiChevronRight, FiEye, FiTrash} from "react-icons/fi";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import {motion} from "framer-motion";
import {SearchTerm} from "../../../../components/SearchTerm";
import CustomButtonNetwork from "../button/CustomButon";
import CreateSelect from "../../../select/components/form/CreateSelect";
import FormSelectOptions from "../../../selectoptions/components/form/FormSelectOptions";

const CustomTables = ({
    data,
    row,
    fullWidth,
    handler,
    handlerView,
    itemPerPage= 10,
    tableFor,
    setIsSubmitting,
    id,
    method,
    handlerDelete,
    isStandard,
    isModified,
}) => {

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [isOpenBox, setIsOpenBox] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const [result, setResult] = useState([]);
    const [idToDelete, setIdToDelete] = useState(null);

    useEffect(() => {
        const res = data?.filter(item =>
            item.name?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setResult(res);
    }, [searchTerm, data]);

    // Pagination in the filter
    const startIndex = (currentPage - 1 ) * itemPerPage;
    const endIndex = Math.min(startIndex + itemPerPage, result.length);
    const sortItem = result.sort((a,b) => {
        return a.name.localeCompare(b.name);
    });
    const currentItems = sortItem?.slice(startIndex, endIndex);

    const totalPages = Math.ceil(result?.length / itemPerPage);

    const handleNextPage = (e) => {
        e.preventDefault()
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = (e) => {
        e.preventDefault()
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleOpenBox = () => {
        setIsOpenBox(!isOpenBox);
    }

    const handlerDeleteWhenConformDialogOpen = () => {
        handlerDelete(idToDelete);
    }

    const totalItem = result?.length || 0;

    return (
        <div className={`w-full flex flex-col gap-8 relative h-[770px]`}>
            <div className="flex items-center justify-between">
                <div className="w-80">
                    <SearchTerm
                        placeHolder="Search by name"
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        network
                    />
                </div>
                <div className="relative">
                    {(isStandard && tableFor === "selectOption") ?
                        ""
                        :
                        <CustomButtonNetwork
                            title={isOpenBox ? "Close" : tableFor === "customSelect" ? "Create new Select" : "Create new select option"}
                            handler={handleOpenBox}
                            variant="contained"
                            isOpenBox={isOpenBox}
                        />
                    }

                    {isOpenBox &&
                        tableFor === "customSelect" &&
                        <CreateSelect
                            id={id}
                            type="network"
                            setIsSubmitting={setIsSubmitting}
                            setIsOpenBox={setIsOpenBox}
                        />
                    }

                    {isOpenBox &&
                        tableFor === "selectOption" &&
                        <FormSelectOptions
                            id={id}
                            setIsOpenBox={setIsOpenBox}
                            method={"POST"}
                            setIsSubmitting={setIsSubmitting}
                        />
                    }
                </div>
            </div>

            <motion.table
                className={`relative overflow-y-auto bg-white rounded-sm ${result?.length !== 0 ? "max-h-[550px]" : "h-[550px]"} ${fullWidth ? 'w-full' : ''}`}
                initial={{opacity: 0, translateY: 5}}
                animate={{opacity: 1, translateY: 0}}
                transition={{type: 'spring', duration: .5, ease: "linear"}}
            >
                <thead className="text-xs text-gray-700 uppercase bg-slate-50">
                <tr className="flex">

                    {/*{row?.map(item =>*/}
                    {/*    <th scope="col"*/}
                    {/*        className={`px-6 py-3 ${item.growElement ? "grow w-1/6" : "w-24"} ${item.centerElement ? "text-center" : "text-left"}`}>*/}
                    {/*        {item.label}*/}
                    {/*    </th>*/}
                    {/*)}*/}

                    <th scope="col"
                        className={`px-6 py-3 text-center w-24 flex items-center justify-center"}`}>
                        ID
                    </th>

                    <th scope="col" className="px-6 py-3 text-left grow w-1/6">
                        Name
                    </th>
                    {tableFor === "customSelect" &&
                        <>
                            <th scope="col" className="px-6 py-3 text-center w-24 flex items-center justify-center">
                                Options
                            </th>

                            <th scope="col" className="px-6 py-3 text-center w-24 flex items-center justify-center">
                                Standard
                            </th>
                        </>
                    }

                    <th scope="col" className="px-6 py-3 text-center w-40 flex items-center justify-center">
                        Action
                    </th>
                </tr>
                </thead>
                <tbody className={`text-slate-500 text-sm`}>

                {totalItem === 0 ?
                    <tr className={`bg-white border-b flex items-center hover:bg-zinc-50 min-h-10`}>
                        <td className="w-1/6 px-6 py-4 grow text-left">
                            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border border-slate-200 h-8 flex items-center px-2">
                                No result found
                            </span>
                        </td>
                    </tr>
                    :
                    currentItems?.map((item) =>
                        <tr className={`bg-white border-b flex items-center hover:bg-zinc-50 min-h-10`} key={item.id}>
                            <th scope="row"
                                className="w-24 text-left px-6 py-4 font-medium text-slate-700 whitespace-nowrap">
                                {item.id}
                            </th>
                            <td className="w-1/6 px-6 py-4 grow text-left">
                                {item.name}
                            </td>
                            {tableFor === "customSelect" &&
                                <>
                                    <td className="w-24 px-6 py-4 text-right flex items-center justify-center">
                                        {item.listSelectOptions?.length ? item.listSelectOptions.length : 0}
                                    </td>

                                    <td className="w-24 px-6 py-4 text-right flex items-center justify-center">
                                        {item.isStandard ?
                                            <span className="text-xs text-slate-500">True</span>
                                            :
                                            <span className="text-xs text-slate-500">False</span>
                                        }
                                    </td>
                                </>
                            }

                            <td className="px-6 py-4 text-right w-40 flex items-center gap-2 justify-center">
                                {(isStandard && tableFor === "selectOption") ?
                                    ""
                                    :
                                    (tableFor === "customSelect" && item.isStandard) ?
                                        <button
                                            type="button"
                                            onClick={() => {
                                                // handlerView(item.id);
                                                if (tableFor === "selectOption" && method === "PUT") return handlerView(item.id);
                                                handler(item.id);
                                            }}
                                            className={`text-xs border border-slate-300 rounded-full h-6 w-6 flex items-center justify-center text-slate-700 hover:bg-blue-500 hover:text-slate-50 transition ease-linear`}
                                        >
                                            <span><FiEye/></span>

                                        </button>
                                        :
                                        <>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    // handlerView(item.id);
                                                    if (tableFor === "selectOption" && method === "PUT") return handlerView(item.id);
                                                    handler(item.id);
                                                }}
                                                className={`text-xs border border-slate-300 rounded-full h-6 w-6 flex items-center justify-center text-slate-700 hover:bg-blue-500 hover:text-slate-50 transition ease-linear`}
                                            >
                                                <span><FiEye/></span>

                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setIdToDelete(item.id);
                                                    setOpenConfirmation(true)
                                                }}
                                                className={`text-xs border border-red-200 rounded-full h-6 w-6 flex items-center justify-center text-red-400 bg-red-50 hover:bg-red-500 hover:text-slate-50 transition ease-linear`}>
                                                <span><FiTrash/></span>
                                            </button>


                                            <ConfirmDialog
                                                isOpen={openConfirmation}
                                                onClose={() => setOpenConfirmation(false)}
                                                setIsOpen={setOpenConfirmation}
                                                onAgree={handlerDeleteWhenConformDialogOpen}
                                            />
                                        </>
                                }

                            </td>
                        </tr>
                    )}
                </tbody>
            </motion.table>

            {result.length !== 0 &&
                <ul className={`flex items-center justify-between absolute bottom-0 left-0 w-full`}>
                    <li>
                        <p className="text-sm text-slate-500">Showing <span
                            className="text-slate-700">{startIndex + 1}-{endIndex}</span> of <span
                            className="text-slate-700">{totalItem}</span></p>
                    </li>
                    <li className="flex gap-4 items-center">
                        <button
                            onClick={handlePrevPage}
                            className="text-sm text-slate-500 border border-slate-200 h-10 w-10 rounded-full flex items-center justify-center hover:bg-slate-100 transition ease-linear">
                            <FiChevronLeft/>
                        </button>
                        <button
                            onClick={handleNextPage}
                            className="text-sm text-slate-500 border border-slate-200 h-10 w-10 rounded-full flex items-center justify-center hover:bg-slate-100 transition ease-linear">
                            <FiChevronRight/>
                        </button>
                    </li>
                </ul>
            }
        </div>
    );
};

export default CustomTables;
