import {isPPARoute} from "./variables";

export const dataHomeSection = [
    {
        id: 1,
        title: "Submit Your Proposal Projects",
        btnText: "Submit",
        description: "Submit your innovative ProjectProposal proposals and contribute to advancing scientific knowledge. Share your ideas and collaborate with a community of forward-thinking researchers. ",
        image: "ppabg",
        path: "ppa",
        type: "ppa",
        isSoon:isPPARoute
    },
    {
        id: 2,
        title: "Browse Our Scientific Catalogs",
        btnText: "Discover",
        description: "Explore a vast collection of catalogs, theses, and cutting-edge research. Easily access valuable information and stay at the forefront of scientific innovation",
        image: "catalogbg1",
        path: "catalog",
        type: "catalog",
        isSoon:false
    },
]

export const dataNotifMenuAuth = [
    {
        id: 1,
        title: "Inbox",
    },
    {
        id: 2,
        title: "Archive",
    },
]