import {motion} from "framer-motion";
import {useParams} from "react-router-dom";
import {useFetchNetworkById} from "../../../network/hooks/useFetchNetworkById";
import {FiSend} from "react-icons/fi";
import {toast} from "sonner";
import useCatalogContext from "../../../../hooks/useCatalogContext";

export const ButtonPpa = ( { catalog } ) => {

    const { styleCatalog } = useCatalogContext("", catalog, 500);

    const { id } = useParams();

    const { data } = useFetchNetworkById(id);

    const handleClickPpa = () => {

        if (data) return window.open(`/ppa/${data.code}`, '_blank');

        return toast.warning("Something went wrong. Try again later.");
    }

    return (
        <motion.button
            type="button"
            onClick={handleClickPpa}

            className={`${catalog ? styleCatalog : "bg-blue-500 text-slate-50" } h-12 rounded-t-3xl absolute bottom-0 left-0 w-full flex items-center justify-center gap-4 z-10`}
            title="submit project"
        >
            Send submit project <span className="h-6 w-6 text-xs flex items-center justify-center border border-slate-200 text-slate-200 rounded-full"><FiSend/></span>
        </motion.button>
    )
}