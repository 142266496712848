import React, { useState } from 'react';
import {FiFilter, FiX} from "react-icons/fi";
import { motion } from "framer-motion";
import {FaSquare} from "react-icons/fa";
import {truncateTextLetter} from "../../../utils/truncateText";

const NetworkHeader = ({ setActiveMenu, filters, setFilters, dataTitle, lengthofTabFilters }) => {
    const [boxFilter, setBoxFilter] = useState(false);

    const handleClickAction = (idMenu) => {
        if (idMenu === 1) return;
        if (idMenu === 2) return setBoxFilter(!boxFilter);
    }

    const handleCheckedFilter = (idFilter) => {
        setFilters(filters.map(filter =>
            filter.id === idFilter ? { ...filter, checked: !filter.checked } : filter
        ));
    }

    const handleCloseFilter = () => {
        setBoxFilter(false);
    }

    const isChecked = filters.some(filter => filter.checked);

    const tabsMenu = [
        {
            id: 1,
            title: dataTitle,
        },
        {
            id: 2,
            title: "Filter",
            icon: <FiFilter />,
        }
    ];

    return (
        <div className={`h-14 z-20 bg-transparent shadow-sm flex items-center border-b border-slate-300 relative`}>
            <div className="lg:w-11/12 mx-auto flex items-center justify-start gap-10">

                <div className="grow divide-x divide-slate-200 flex gap-4 items-center">
                    <ul className="flex items-center gap-6">
                        {tabsMenu.map((item, index) =>
                            <li
                                key={item.id}
                                className={`relative text-slate-500`}
                            >
                                <button
                                    type="button"
                                    className={`relative flex items-center gap-2 cursor-pointer ${item.id === 1 ? "cursor-default font-bold text-slate-800 underline" : "font-medium group-hover:text-slate-400 text-slate-500"}`}
                                    onClick={() => handleClickAction(item.id)}
                                    // title={ item.id === 1 && item.title }
                                    title={ item.id === 1 ? item.title : undefined }
                                >
                                    { truncateTextLetter( item.title, 25)} {item.icon && <span className={`${item.id === 2 && isChecked ? "text-blue-500" : "text-slate-400"}`}>{item.icon}</span>}

                                    {isChecked && item.id === 2 && <span className="bg-blue-500 rounded-full h-2 w-2 absolute top-0 -right-2"></span>}
                                </button>


                                {boxFilter && item.id === 2 &&
                                    <motion.div
                                        className={`absolute drop-shadow-sm top-14 left-0 border border-slate-200 bg-white rounded-xl w-52`}
                                        initial={{opacity: 0, translateY: 25}}
                                        animate={{opacity: 1, translateY: 0}}
                                        exit={{opacity: 0}}
                                        transition={{type: "spring", duration: .5, ease: "linear"}}
                                    >
                                        <div
                                            className="flex items-center justify-center h-10 border-b border-slate-200 bg-slate-200 relative">
                                            <p className="text-slate-700">Choose filter...</p>
                                            <button
                                                className="border border-slate-400 rounded-full bg-white text-xl flex items-center justify-center absolute -right-2 -top-4 p-1 hover:bg-slate-50 hover:text-slate-400 transition ease-in"
                                                onClick={handleCloseFilter}
                                            ><FiX/></button>
                                        </div>
                                        <div>
                                            <ul className="flex flex-col divide-y divide-slate-200">
                                                {filters.map(item =>
                                                    <li key={item.id}
                                                        className="flex items-center gap-2 cursor-pointer p-2 text-slate-700 text-sm"
                                                        onClick={() => handleCheckedFilter(item.id)}
                                                    >
                                                        <span
                                                            className={`${item.checked ? "text-blue-400 border-blue-500" : "text-white"} border border-slate-300 transition ease-in`}><FaSquare/></span>
                                                        {item.title}
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </motion.div>
                                }
                            </li>
                        )}
                    </ul>

                    <ul className="px-2 flex items-center divide-x divide-slate-200">
                        <li className="pr-2">
                            {!isChecked ?
                                <span className="text-sm text-slate-400">No filter applied</span>
                                :
                                <span className="text-sm text-blue-500 underline">Filter applied</span>
                            }
                        </li>
                        <li className="px-2">

                        <span className="text-sm text-slate-400">There {lengthofTabFilters <= 1 ? "is " : "are "}
                            <span className="text-slate-700 underline">{lengthofTabFilters}</span> {lengthofTabFilters <= 1 ? "tab" : "tabs"}</span>
                        </li>

                    </ul>

                </div>
            </div>
        </div>
    );
};

export default NetworkHeader;