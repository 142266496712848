import AxiosService from "../../../services/AxiosService";


const urlInput_Type = "api/input_types";
const urlNetworkInputTypes = "api/input_types/network/description";
const urlPpaInputTypes = "api/input_types/ppa/description";

const urlInputTypesByFeatureCode = "api/input_types/config";

class InputsTypeServices {
    GetInputsType(token, isForNetwork = false) {
        if (isForNetwork) {
            return AxiosService.getWithToken(urlInput_Type + '?isForNetwork=1', token, "jsonld");
        }
        return AxiosService.getWithToken(urlInput_Type, token, "jsonld");
    }

    GetInputTypeByCode(code, token) {
        return AxiosService.getWithToken(urlInput_Type + "?code=" + code, token, "jsonld");
    }

    GetInputTypeById(id, token) {
        return AxiosService.getWithToken(urlInput_Type + "/" + id, token, "jsonld");
    }

    AddInputType(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(urlInput_Type, data, "jsonld");
    }

    EditInputType(data, id, token) {
        return AxiosService.putWithToken(urlInput_Type + "/" + id, data, token, "jsonld");
    }

    DeleteInputype(id, token) {
        return AxiosService.deleteWithToken(urlInput_Type + "/" + id, token, "jsonld");
    }

    /* [CONFIG] OneForm - Input Types */
    getInputTypesByFeatureCode(featureCode, token) {
        return AxiosService.getWithToken(urlInputTypesByFeatureCode + "/" + featureCode, token, "jsonld");
    }
}

export default InputsTypeServices;