import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

// Services
import PPAService from "../../../services/PPAService";
const ppaService = new PPAService();


export const useFetchPpaTypeFromCode = (ppaTypeCode, setIsLoading) => {

    const [isPpaLocked, setIsPpaLocked] = useState(false);
    const [ppaLockedMessage, setPpaLockedMessage] = useState('');
    const defaultErrorMessage = "This pre-proposal form doesn't exist"

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {

            if (ppaTypeCode) {
                async function handleGetPpaTypeFromCode() {
                    try {

                        setIsLoading(true)

                        const response = await ppaService.getPPANetworkFromPPANetworkCode(ppaTypeCode);

                        if (response.length !== 0) {
                            if (response.isLocked) {
                                setIsPpaLocked(true);
                                setPpaLockedMessage(response.lockMessage)
                            }
                        } else {
                            setIsPpaLocked(true);
                            setPpaLockedMessage(defaultErrorMessage)
                        }

                        setIsLoading(false)

                    } catch (error) {
                        console.error(error);
                        navigate('/ppa');
                    }
                }
                handleGetPpaTypeFromCode().then();
            }

        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [ppaTypeCode]);

    return { isPpaLocked, ppaLockedMessage };
}