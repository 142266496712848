import {useEffect, useState} from "react";

// Redux
import { useDispatch } from "react-redux";
import {reusableFormSlice} from "../store/reusableFormSlice";

import PPAService from "../../../services/PPAService";
import SelectService from "../../../services/SelectService";
import InfrastructureService from "../../../features/installation/service/InfrastructureService";

const ppaService = new PPAService();
const selectService = new SelectService();
const infrastructureService = new InfrastructureService();


export const useFetchFormSelectInfras = (listInfrastructures, networkId) => {

    const [selectInfras, setSelectInfras] = useState([]);
    const [isLoadingSelectInfras, setIsLoadingSelectInfras] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {

            // if(listInfrastructures && Array.isArray(listInfrastructures)) {
            if(listInfrastructures) {
                async function handleGetPpaSelectInfras(){
                    try {
                        setIsLoadingSelectInfras(true);

                        const response = await infrastructureService.getInfrastructureByNetwork(networkId);
                        const hydraMember = response["hydra:member"];
                        // console.log(hydraMember)

                        setSelectInfras(hydraMember);
                        setIsLoadingSelectInfras(false);

                    } catch (error) {
                        console.error(error);
                    }
                }
                handleGetPpaSelectInfras().then();
            }

        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [networkId]);

    return { selectInfras, isLoadingSelectInfras };

}


export const useFetchFormPreloadServices = (networkId) => {

    const [preloadServices, setPreloadServices] = useState([]);
    const [isLoadingPreloadServices, setIsLoadingPreloadServices] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {

            if(networkId) {
                async function handleGetPpaPreloadServices(){
                    try {
                        setIsLoadingPreloadServices(true);
                        const response = await ppaService.getPPAPreloadServices(networkId);
                        const hydraMember = response["hydra:member"];
                        // console.log(hydraMember)

                        setPreloadServices(hydraMember);
                        setIsLoadingPreloadServices(false);

                    } catch (error) {
                        console.error(error);
                    }
                }
                handleGetPpaPreloadServices().then();
            }

        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [networkId]);

    return { preloadServices, isLoadingPreloadServices };

}