import React from "react";

// Design
import {Grid} from "@mui/material";

// Components
import {CardSingleItem} from "./CardSingleItem";


const CardItem = ({ searchQuery, networkData, searchList, nextItems, handleDrawerOpen }) => {

    const handleClick = () => {
        handleDrawerOpen();
    }

    return (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2 }} sx={{ margin: '20px 0' }}>
            {((searchQuery === '') ? networkData : searchList).slice(0, nextItems)?.map( network =>

                <CardSingleItem key={network.id} network={network} handleClick={handleClick} />

            )}
        </Grid>
    )
}

export default CardItem;