import {CompCustom} from "../../CompCustom";

export const InstallationBox = ({ data, id, filters, networkId, setLengthofTabFilters, fetchData }) => {

    return (
        <div className="wrapper pt-10 flex flex-col items-start h-full relative">
            <CompCustom
                id={id}
                networkId={networkId}
                filters={filters}
                type="installation"
                data={data}
                setLengthofTabFilters={setLengthofTabFilters}
                fetchData={fetchData}
            />
        </div>
    )
}