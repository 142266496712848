import React, {memo, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from "react-router-dom";

// Components
import {PlatformListView} from "./PlatformListView";
import {FiSettings} from "react-icons/fi";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {changeInfrastructure} from "../../../redux/user/changeInfrastructureAction";
import {createSelector} from "reselect";

// Custom Hooks
import {useFetchPlatform} from "../hook/useFetchPlatform";
import {useInfrastructuresSelect} from "../../../hooks/useInfrastructuresSelect";
import {useFetchUserRole} from "../../../hooks/useFetchUserRole";
import {useMobile} from "../../../hooks/useMobile";

// Design
import {PlatformListSwitch} from "./PlatformListSwitch";


const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);

const selectUser = createSelector(
    state => state.login.user,
    user => user
);


export const PlatformList = memo(function PlatformList() {
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    const user = useSelector(selectUser);
    const token = user?.token;

    const location = useLocation();

    const role = useFetchUserRole();

    const isMobile = useMobile();

    // const {data } = useFetchPlatform(token);
    useFetchPlatform(token);
    useInfrastructuresSelect(currentInfrastructure, token);
    const data = useSelector((state) => state.platformReducers.data);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChangePlatform = async (e, platform) => {
        const getId = new Promise((resolve) => {

            let id = null !== platform ? platform.id : 0;

            if (null !== platform && null !== platform.parent && false === platform.parent.isGroupOnly) {
                id = platform.parent.id;
            }
            resolve(id);
        });

        await getId.then(id => {
            dispatch(changeInfrastructure(id));
            if (id > 0) {
                navigate("/dashboard");
            } else {
                navigate("/")
            }
        });
    }


    /***************** Toggle PlatformListView Mode *********************/
    const groupedPlatformListFromStorage = localStorage.getItem("platformListGroupMode") && JSON.parse(localStorage.getItem("platformListGroupMode"));
    const [groupedPlatformList, setGroupedPlatformList] = useState(groupedPlatformListFromStorage ?? false);
    const label = { inputProps: { 'aria-label': 'Toogle Installation List Mode' } };

    const handleChangeListMode = (grouped) => {
        localStorage.setItem("platformListGroupMode", JSON.stringify(grouped));
        setGroupedPlatformList(grouped);
    }


    /***************** TODO - Single Installation AutoSelection *********************/
    useEffect(() => {
        /* Array length : 2 maximum (can include a Group Header item) */
        if (data.length !== 0 && data.length < 3) {

            // const defaultInstallation = data.filter((item) => !item.isGroupOnly).find(item => item);
            // console.log(defaultInstallation);
            // (!currentInfrastructure) && handleChangePlatform('default', defaultInstallation).then();
        }

        !user?.isSuperAdministrator && handleChangeListMode(false);
    }, []);


    /***************** List Management *********************/
    let filterWithParentGroupOnlyFalse = data?.filter( option => false === option.isGroupOnly);

    /**** Shared Functions ****/
    const removeSameIdsOccurences = (sourceArray, targetArray) => {
        const detectedIds = new Set();

        sourceArray.forEach((item) => {
            if (!detectedIds.has(item?.id)) {
                targetArray.push(item);
                detectedIds.add(item?.id);
            }
        });
    }

    const placeChildrenRightAfterParents = (sourceArray, targetArray) => {
        sourceArray.forEach((element) => {

            const indexFound = targetArray.findIndex((found) => found.id === element?.parent?.id);

            if (indexFound !== -1) {

                let nextIndex = indexFound + 1;
                while (targetArray[nextIndex] && targetArray[nextIndex]?.parent?.id === element?.parent?.id) {
                    nextIndex++
                }
                /* Insert at the right place */
                targetArray.splice(nextIndex, 0, element);
            }
        })
    }


    /**** Grouped Headers & Children In No Parent Installations - Group Mode ON ****/

    /**** Order/Group by parent name ****/
    let groupedByParentArrays = filterWithParentGroupOnlyFalse.reduce((accumulator, obj) => {
        let parentName = obj.parent?.name;
        if (!accumulator[parentName]) {
            accumulator[parentName] = [];
        }
        accumulator[parentName].push(obj);
        return accumulator;
    }, {});

    // console.dir(groupedByParentArrays)

    let arrayOfGroupedByParentArrays = Object.values(groupedByParentArrays);

    /* Insert parent at the top (unshift) of the Group */
    arrayOfGroupedByParentArrays.forEach((element) => {
        const last = element.findLast((found) => found).parent;
        element.unshift(last);
    });

    const completeListOrderedByParentName = arrayOfGroupedByParentArrays.flatMap((item) => item);

    /**** Dissociate No parent (parent: null && isGroupOnly: false) from others ****/
    const arrayWithoutParentNullAndGroupOnlyFalse = completeListOrderedByParentName.filter((item) => !(!item?.isGroupOnly && !item?.parent));

    const arrayFilledWithParentNullAndGroupOnlyFalse = completeListOrderedByParentName.filter((item) => (!item?.isGroupOnly && !item?.parent) && item);
    const noParentGroupHeader = {
        name: completeListOrderedByParentName.length !== 0 ? 'No parent installations' : 'No options',
        logo: '',
        parent: null,
        isGroupOnly: true,
        notANetwork: true,
        noOptions: !(completeListOrderedByParentName.length !== 0)
    }
    const noParentOptionsList = arrayFilledWithParentNullAndGroupOnlyFalse.length !== 0 ? [noParentGroupHeader, ...arrayFilledWithParentNullAndGroupOnlyFalse] : [];

    /* Remove duplicated installations */
    const removeSameParentOccurences = [];
    removeSameIdsOccurences(arrayWithoutParentNullAndGroupOnlyFalse, removeSameParentOccurences);


    /**** Place children right after parents ****/
    /* Root parents array (Group) */
    const rootParents = removeSameParentOccurences.filter((item) => (item?.isGroupOnly && !item?.parent) && item);
    rootParents.sort((a, b) => -b?.name.localeCompare(a.name));

    /* First Level Installation array */
    const firstLevelInstallations = removeSameParentOccurences.filter((item) => (!item?.isGroupOnly && item?.parent && item?.parent?.isGroupOnly) && item);

    const firstLevelOptionsList = [...rootParents];
    placeChildrenRightAfterParents(firstLevelInstallations, firstLevelOptionsList);

    /* Second Level Installation array */
    const secondLevelInstallations = removeSameParentOccurences.filter((item) => (!item?.isGroupOnly && item?.parent && !item?.parent?.isGroupOnly) && item);

    const secondLevelOptionsList = [...firstLevelOptionsList];
    placeChildrenRightAfterParents(secondLevelInstallations, secondLevelOptionsList);

    /* Remove duplicated installations in "No Parent Installations" group */
    const removeInNoParentInstallationsSameParentOccurences = [];
    removeSameIdsOccurences(noParentOptionsList, removeInNoParentInstallationsSameParentOccurences);

    const noParentWithChildrenOptionsList = [...removeInNoParentInstallationsSameParentOccurences];
    placeChildrenRightAfterParents(secondLevelInstallations, noParentWithChildrenOptionsList);

    /* Insert (parent: null && isGroupOnly: false) at the end */
    const finalOptionsList = [...secondLevelOptionsList, ...noParentWithChildrenOptionsList];
    // console.dir(finalOptionsList) // This list includes Group Headers (as items) in total length


    /**** No Grouped Header List Management - Group Mode OFF ****/

    const childrenInstallations = filterWithParentGroupOnlyFalse.filter((item) => (!item?.isGroupOnly && item?.parent && !item?.parent?.isGroupOnly) && item);
    const parentsInstallations = filterWithParentGroupOnlyFalse.filter((item) => !(!item?.isGroupOnly && item?.parent && !item?.parent?.isGroupOnly) && item);

    const noGroupedHeaderList = [...parentsInstallations];
    placeChildrenRightAfterParents(childrenInstallations, noGroupedHeaderList);

    const finalNoGroupedHeaderOptionsList = [...noGroupedHeaderList];
    // console.dir(finalNoGroupedHeaderOptionsList)



    const handleEditInstalltion = () => {
        return navigate("/installation/description");
    }

    const matchInfraDescriptionRoute = location.pathname.match(/^\/installation\/description$/);


    return (
        <div className={`${isMobile ? "bg-slate-50 relative w-full order-first p-4 border-b border-slate-200" : "absolute flex left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"} z-20`}>
            <div className="lg:min-w-96 border border-solid border-slate-200 h-14 rounded-full drop-shadow-sm flex gap-0 overflow-hidden">

                <div className="w-1/6 grow h-full flex items-center justify-center">
                    <PlatformListView
                        finalOptionsList={groupedPlatformList ? finalOptionsList : finalNoGroupedHeaderOptionsList}
                        dataValue={data.find((option) => option.id === currentInfrastructure) ?? null}
                        handleChangePlatform={handleChangePlatform}
                    />
                </div>

                {(currentInfrastructure !== null && currentInfrastructure > 0 && role && (role.isRoleAdmin || user.isSuperAdministrator)) && (

                    <div className={`relative order-first border-none w-14 flex items-center justify-center ${matchInfraDescriptionRoute ? "bg-orange-500" : "bg-slate-200"}`}>
                        <Link to={"/installation/description"}
                              className={`${matchInfraDescriptionRoute ? "text-slate-50" : "text-slate-700 hover:text-orange-500"} text-xl cursor-pointer transition ease-linear`}
                              title={"Click here to edit your installation"}
                        >
                            <FiSettings/>
                        </Link>
                    </div>

                )}
            </div>
            {user?.isSuperAdministrator && (
                <div className={"flex items-center text-sm text-slate-500 ml-2 justify-center pt-2 lg:pt-0"}>
                    <PlatformListSwitch {...label}
                                        checked={groupedPlatformList}
                                        onChange={() => handleChangeListMode(!groupedPlatformList)}
                    />
                    <span className={"text-center relative right-2"}>Grouped list</span>
                </div>
            )}
        </div>
    );
});