import React, {useCallback, useEffect, useState} from "react";

// Design
import {
    Button,
    Collapse,
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Paper, Box,
} from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { AiOutlineEdit } from "react-icons/ai";
import { GrRevert } from "react-icons/gr";
import { AiOutlineDelete } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {HiOutlineBookOpen} from "react-icons/hi2";
import {AnimatePresence, motion} from "framer-motion";
import './listtableview.scss';

// Components
import {FormContentInputs} from "../../FormContentInputs";
import {LIstTypeFormsRemoveLine} from "./LIstTypeFormsRemoveLine";
import {ListTypeFormsSelectsReadOnly} from "./ListTypeFormsSelectsReadOnly";
import {ListTypeFormsFieldsReadOnly} from "./ListTypeFormsFieldsReadOnly";
import Alert from "@mui/material/Alert";

// Custom Hooks
import {useMobile} from "../../../../../../hooks/useMobile";

// Slider (Swiper)
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation, Pagination, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import SwiperCore from 'swiper';

SwiperCore.use([Pagination, Navigation, Thumbs]);


export const ListTypeFormsTableViewSwiper = ({ initialJoinedInputsLength,
                                           mapListInputs,

                                           listinputarray,
                                           listindexarray,
                                           keyLinkValue,

                                           handleSaveInputs,
                                           handleRemoveInputs,
                                           handleCancelInputs,

                                           selectOptions,
                                           formId,
                                           preloadServices,
                                           values,
                                           isRead,

                                           listTypeForms

                                       }) => {


    const rowTitles = mapListInputs.filter((item) => !item.isHidden).map((item) => item.name);
    const retrievedListInputValues = [...values.listInputValues]
    let isFirstEdition = listinputarray[listinputarray.length - 1].isFirstEdition;

    const [open, setOpen] = useState(true);
    const [showActionsMenu, setShowActionsMenu] = useState(false);
    const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
    const [showPreventActionsMenu, setShowPreventActionsMenu] = useState(false);
    const [dataInStorage, setDataInStorage] = useState(false);


    const handleSaveChanges = () => {
        handleSaveInputs(keyLinkValue)
        setOpen(!open);
        setDataInStorage(true)
    }

    const handleActionsMenu = () => {
        setShowActionsMenu(!showActionsMenu);
    }

    const handleRemoveConfirm = () => {
        setShowRemoveConfirm(!showRemoveConfirm);
    }

    const handlePreventActionsMenu = () => {
        setShowPreventActionsMenu(!showPreventActionsMenu);
    }

    // Close all editions except for new edition
    useEffect(() => {
        (values.token !== null && !isFirstEdition) && setOpen(false);
    }, [values.token]);

    // Close all actions after a line removal
    useEffect(() => {
        setShowActionsMenu(false);
        setShowRemoveConfirm(false)
    }, [listinputarray]);


    // Detect the number of line(s) in a single Form
    const lineFormWrapper = document.getElementsByClassName('line-grid-wrapper');

    const [lineCountInForm, setLineCountInForm] = useState(lineFormWrapper ? lineFormWrapper.length : 0);

    const handleLineCountInForm = () => {

        // Create new array to push Form ids to be counted
        let displayedFormsIds = []

        for (let line of lineFormWrapper) {
            formId === Number(line.dataset.formid) && displayedFormsIds.push(Number(line.dataset.formid));
        }

        setLineCountInForm(displayedFormsIds.length);
    }

    useEffect(() => {
        handleLineCountInForm();
    }, [listinputarray]);


    /***** SELECTS *****/
        // PPA Case || Descriptions Case
    const filteredSelects = selectOptions.filter((optionList) => optionList.form ? (optionList.form['@id'] === `/api/forms/${formId}`) : (optionList.formId === formId))

    const selectInputs = filteredSelects.map((optionList) => (optionList.select));
    const selectOptionsInCurrentTab = selectInputs.flatMap((option) => option.listSelectOptions ? option.listSelectOptions : []);

    // Use URI checking to dispose Inputs in right Form
    // flatMap to avoid : arrays in array
    const apiInputsArrayInForm = listTypeForms.filter((list) => list.id === formId).flatMap((item) => item.listInputs.map((input) => input['@id']));


    // Swiper
    const isMobile = useMobile();
    const [totalSlide, setTotalSlide] = useState(0);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [mainSwiper, setMainSwiper] = useState(null);
    const [showHeaderLabels, setShowHeaderLabels] = useState(false);

    const handleSwiperLabelsInit = useCallback((swiperInstance) => {
        setThumbsSwiper(swiperInstance);
        setTotalSlide(swiperInstance.slides.length);
    }, []);

    const handleLabelItemChange = (index) => {
        if (mainSwiper && mainSwiper.slideTo) {
            mainSwiper.slideTo(index);
        }
    };

    const handleValueItemChange = (index) => {
        if (thumbsSwiper && thumbsSwiper.slideTo) {
            thumbsSwiper.slideTo(index);
        }
    };


    return (
        <Grid container
              className={'list-type-saved-mode odd:bg-white even:bg-neutral-100 line-grid-wrapper'}
              sx={{ position: 'relative' }}
              aria-expanded={open}
              data-formid={formId}
        >

            <TableContainer component={Paper} id={`list-type-form-table`} className={`list-type-form-table`} sx={{ width: {xs:'90vw', lg: '100%'}, overflow: 'hidden', borderRadius: {xs:'unset', lg: 'initial'} }}>
                <Table className={'list-type-form-table-main text-slate-900'}
                       aria-label="collapsible table"
                       sx={{ display: 'flex', flexFlow: 'row wrap', overflow: 'hidden', position: 'relative' }}
                >

                    <Swiper
                        modules={[Pagination, Navigation, Thumbs]}
                        onSwiper={handleSwiperLabelsInit}
                        mousewheel={true}
                        slidesPerView={isMobile ? 2 : (totalSlide > 8 ? 8 : 5)}
                        className={`list-type-form-table-head ml-0 w-full relative ${(showHeaderLabels && !open) && 'show-header-labels'}`}
                        tag={'thead'}
                        wrapperTag={'tr'}
                        wrapperClass={`row ${totalSlide > 8 ? 'w-[90%]' : 'w-full'}`}
                        onSlideChange={(swiper) => {handleLabelItemChange(swiper.activeIndex)}}
                        grabCursor={true}
                    >
                        {rowTitles.map((title, index) => (
                            <SwiperSlide key={`${title}-${index}`}
                                         tag={'th'}
                                         className={`cell`}
                                         title={title}
                            >
                                {title}
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <Swiper
                        modules={[Pagination, Navigation, Thumbs]}
                        onSwiper={setMainSwiper}
                        mousewheel={true}
                        slidesPerView={isMobile ? 2 : (totalSlide > 8 ? 8 : 5)}
                        className={`list-type-form-table-body flex-wrap ml-0 w-full`}
                        tag={'tbody'}
                        wrapperTag={'tr'}
                        wrapperClass={`row input-datas relative text-slate-800 ${totalSlide > 8 ? 'w-[90%]' : 'w-full'}`}
                        onSlideChange={(swiper) => {handleValueItemChange(swiper.activeIndex)}}
                        grabCursor={true}
                        updateOnWindowResize={true}
                        thumbs={{
                            swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                        }}

                        onTouchStart={() => {setShowHeaderLabels(true)}}
                        onTouchEnd={() => {setShowHeaderLabels(false)}}
                    >

                        {retrievedListInputValues?.slice(initialJoinedInputsLength).map((input, index) => (
                            (
                                (input !== undefined && input.keyLink === keyLinkValue) &&
                                (apiInputsArrayInForm.includes(`${input.input}`))
                            ) && (
                                <SwiperSlide key={`${input}-${index}`}
                                             tag={'td'}
                                             className={`cell`}
                                >

                                    {/* SELECT OR OTHERS FIELDS DETECTION */}
                                    {filteredSelects.find((filtered) => `/api/inputs/${filtered.id}` === input.input) ? (
                                        <ListTypeFormsSelectsReadOnly selectOptionsInCurrentTab={selectOptionsInCurrentTab}
                                                                      input={input} // retrievedListInputValues datas
                                        />
                                    ) : (
                                        <ListTypeFormsFieldsReadOnly input={input}
                                                                     mapListInputs={mapListInputs}
                                        />
                                    )}

                                </SwiperSlide>
                            )
                        ))}

                        <Box component={'tr'}
                             sx={{  position: 'absolute',
                                    right: '-6px',
                                    padding: '0',
                                    height: '60px',
                                    width: '60px'
                            }}
                        >
                            <motion.td
                                initial={{x: 0, opacity: 0}}
                                animate={{x: 0, opacity: 1}}
                                transition={{duration: .6}}
                                className={'flex relative h-full w-fit m-auto z-20 bg-white pl-2'}
                            >

                                <Button onClick={(isFirstEdition && lineCountInForm === 1) ? handlePreventActionsMenu : handleActionsMenu }
                                        sx={{zIndex: '3'}}
                                        className={'menu-button'}
                                        aria-label="expand menu"
                                        onBlur={() => {showPreventActionsMenu && setShowPreventActionsMenu(false)}}
                                >
                                    <BiDotsVerticalRounded className={'text-4xl p-1 border-4 border-double rounded-3xl border-stone-400'} />
                                </Button>

                                <AnimatePresence>
                                    {showActionsMenu && (
                                        <motion.div
                                            initial={{x: 0, opacity: 0}}
                                            animate={{x: -90, opacity: 1}}
                                            transition={{duration: .3}}
                                            exit={{ x: 0, opacity: 0 }}
                                            className={'absolute h-full min-w-[90px] flex justify-end z-2 bg-white'}
                                        >
                                            {!isFirstEdition && (
                                                <Button onClick={() => setOpen(!open)}
                                                        className={'menu-button'}
                                                        aria-label="expand row"
                                                >
                                                    {!isRead ? (
                                                        <AiOutlineEdit className={'text-4xl p-1 border-4 border-double rounded-3xl border-blue-400'} />
                                                    ) : (
                                                        <HiOutlineBookOpen className={'text-4xl p-1 border-4 border-double rounded-3xl border-blue-400'} />
                                                    )}
                                                </Button>
                                            )}

                                            {(lineCountInForm > 1 && !isRead) && (
                                                <Button onClick={() => handleRemoveConfirm()}
                                                        className={'menu-button'}
                                                        aria-label="remove row"
                                                >
                                                    <AiOutlineDelete className={'text-4xl p-1 border-4 border-double rounded-3xl text-red-400 border-red-400'} />
                                                </Button>
                                            )}

                                        </motion.div>
                                    )}

                                    {showPreventActionsMenu && (
                                        <motion.div
                                            initial={{x: 0, opacity: 0}}
                                            animate={{x: -110, opacity: 1}}
                                            transition={{duration: .3}}
                                            exit={{ x: 0, opacity: 0 }}
                                            className={'absolute h-full min-w-[100px] flex justify-end z-2 pr-2 z-10'}
                                        >
                                            <div className={`absolute right-0 w-fit flex gap-2 items-center z-50  px-2 py-1 bg-white border rounded-md shadow-sm shadow-slate-300`}>
                                                <Alert severity="error"
                                                       sx={{ width: '40px',
                                                           height: '34px',
                                                           padding: '2px',
                                                           justifyContent: 'center',
                                                           alignItems: 'center',
                                                           '& .MuiAlert-icon': {
                                                               padding: '0',
                                                               margin: '0'
                                                           }
                                                       }}
                                                />
                                                <span className={"text-sm w-full text-nowrap font-semibold"}>First line save is required</span>
                                            </div>

                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </motion.td>

                            {/* Remove Confirmation */}
                            <LIstTypeFormsRemoveLine showRemoveConfirm={showRemoveConfirm}
                                                     handleRemoveConfirm={handleRemoveConfirm}
                                                     handleRemoveInputs={handleRemoveInputs}
                                                     listindexarray={listindexarray}
                                                     listinputarray={listinputarray}
                            />

                        </Box>

                    </Swiper>
                </Table>

                <Table>
                    <TableBody>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset!important' } }}>
                            <TableCell style={{padding: '0 20px'}} colSpan={mapListInputs.length + 1}>
                                <Collapse in={open}
                                          timeout="auto"
                                    // timeout={400}
                                    // unmountOnExit
                                          sx={{
                                              '.MuiCollapse-wrapperInner': {
                                                  display: 'flex',
                                                  flexFlow: 'row wrap',
                                                  gap: '10px 10px',
                                                  margin: '30px 0 40px',
                                                  width: {xs: '99%', lg: '100%'}
                                              }
                                          }}
                                >
                                    <Grid container
                                          sx={{position: 'relative'}}
                                          rowSpacing={{xs: 2, lg: 4}}
                                          columnSpacing={{xs: 2, lg: 4}}
                                    >
                                        <>
                                            {listinputarray.map((listinput, indexform) => (

                                                <React.Fragment key={indexform}>

                                                    {/* INPUTS DATA */}
                                                    {listinput.data && (
                                                        <FormContentInputs inputData={listinput.data}
                                                                           inputIndex={listinput.indexValue}
                                                                           selectOptions={selectOptions}
                                                                           formId={formId}
                                                                           preloadServices={preloadServices}
                                                                           values={values}
                                                                           keyLinkValue={listinputarray[listinputarray.length - 1].keyLinkNumber}
                                                                           isRead={isRead}
                                                        />
                                                    )}

                                                </React.Fragment>

                                            ))}

                                            {!isRead && (
                                                <Grid item xs={12} className={"actions"}>
                                                    <Button onClick={() => handleSaveChanges()}
                                                            sx={{ padding: '6px 12px', minWidth: 'unset' }}
                                                            variant="contained"
                                                            color="success"
                                                    >
                                                        <PostAddIcon sx={{ margin: '0 10px 0 0' }}/>
                                                        <span>Save</span>
                                                    </Button>

                                                    {( dataInStorage && (
                                                            <Button onClick={() => { handleCancelInputs(listindexarray); setOpen(!open) }}
                                                                    sx={{ padding: '6px 12px', minWidth: 'unset' }}
                                                                    variant="contained"
                                                                    color="warning"
                                                            >
                                                                <GrRevert className={'text-2xl mr-3'} />
                                                                <span>Cancel</span>
                                                            </Button>
                                                        )
                                                    )}

                                                    {(!isFirstEdition && lineCountInForm > 1) && (
                                                        <Button onClick={() => handleRemoveInputs(listindexarray, listinputarray)}
                                                                sx={{ padding: '6px 12px', minWidth: 'unset' }}
                                                                variant="contained"
                                                                color="error"
                                                        >
                                                            <AiOutlineDelete className={'text-2xl mr-3'} />
                                                            <span>Remove</span>
                                                        </Button>
                                                    )}

                                                </Grid>
                                            )}
                                        </>
                                    </Grid>

                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

            </TableContainer>

        </Grid>
    )
}
