import {CompCustom} from "../../CompCustom";

export const ServiceBox = ({ id, filters, networkId, data, isLoading, fetchData, setLengthofTabFilters }) => {

    return (
        <div className="wrapper pt-10 flex flex-col items-start h-full relative">
            <CompCustom
                id={id}
                networkId={networkId}
                filters={filters}
                type="service"
                setLengthofTabFilters={setLengthofTabFilters}
                data={data}
                fetchData={fetchData}
            />
        </div>
    )
}