import * as React from "react";
import {useContext, useEffect, useState} from "react";

// Design
import {Box} from "@mui/material";

// Components
import {NavigationTabsContainer} from "./NavigationTabsContainer";

// Context
import {NavigationSelectionsMenuContext} from "./NavigationSelectionsMenu";


export const NavigationTabsWrapper = (props) => {

    // Mui Tabs
    const {
        featureStandardDescriptions, standardTabValue, handleChangeStandard,
        featureAdditionalDescriptions, additionalTabValue, handleChangeAdditional,
        descriptionTitle, descriptionSubTitle
    } = props;

    // From Context (Mobile : When Content is in Drawer)
    const navigationSelectionsMenuContext = useContext(NavigationSelectionsMenuContext)
    const {
        largeScreenSelectionClasses: largeScreenSelectionClassesContext,
        isMobile: isMobileContext,
    } = navigationSelectionsMenuContext !== null && navigationSelectionsMenuContext;


    return (
        <Box component={"div"}>
            <div className={`${!isMobileContext && largeScreenSelectionClassesContext} py-6 px-4 w-[280px]`}>
                <div className="mb-8">
                    <div className="flex items-center justify-center">
                        <div className="flex items-center text-xl font-bold text-black">{descriptionTitle}</div>
                    </div>
                    {descriptionSubTitle &&
                        <div className="flex items-center justify-center text-md font-bold text-slate-500 mt-4 pl-4">{descriptionSubTitle}</div>
                    }
                </div>

                <Box component={'div'} className={"w-full flex flex-wrap"}>
                    <Box component={'div'} className={"w-full flex flex-wrap items-center"}>

                        {featureStandardDescriptions.length !== 0 && (
                            <NavigationTabsContainer featureDescriptions={featureStandardDescriptions}
                                                     handleChange={handleChangeStandard}
                                                     value={standardTabValue}
                                                     props={props}
                                                     type="Standard"
                            />
                        )}

                        {featureAdditionalDescriptions.length !== 0 && (
                            <NavigationTabsContainer featureDescriptions={featureAdditionalDescriptions}
                                                     handleChange={handleChangeAdditional}
                                                     value={additionalTabValue}
                                                     props={props}
                                                     type="Additional Fields"
                            />
                        )}
                    </Box>
                </Box>
            </div>
        </Box>
    )
}
