import React from "react";

// Design
import {CustomizedAutoComplete} from "./FormCustomizedInputs";
import {SlArrowDown} from "react-icons/sl";
import {ListSubheader, MenuItem, TextField} from "@mui/material";


export const FormAutoCompleteView = ({ selectData, labelData, multiple, selectedValues, id, fieldUniqueId, name, handleChange, groupedBy, isRead, isBlocked }) => {

    return (
        <CustomizedAutoComplete
            multiple={multiple}
            value={selectedValues}
            id={ fieldUniqueId }
            name={ name }

            // Data Management
            options={selectData ?? []}
            isOptionEqualToValue={(option, value) => option.id === value }

            getOptionLabel={(option) => {

                if (labelData?.find(item => item.id === Number(option))?.name) {
                    return labelData.find(item => item.id === Number(option))?.name
                } else {
                    return `${isNaN(option) ? labelData.find(item => item.id === Number(option.id))?.name : option ?? Number(option.id)}`
                }

            }}

            onChange={(event, newValue) => {
                handleChange(multiple, newValue)
            }}

            // General Props
            disableCloseOnSelect
            disabled={isRead || isBlocked}
            freeSolo
            forcePopupIcon
            popupIcon={<SlArrowDown className={"select-icon text-lg mx-auto"} />}

            // For Grouped Data
            groupBy={groupedBy && groupedBy}
            renderGroup={(params) => {
                return groupedBy && (
                    <React.Fragment key={params.key}>
                        <ListSubheader className="preload-subheader">{params.group}</ListSubheader>
                        {params.children}
                    </React.Fragment>
                )
            }}

            // Every Render Cases
            renderInput={(params) => (
                <TextField {...params}
                           inputProps={{
                               ...params.inputProps,
                               'data-inputid':`${id}`
                           }}
                />
            )}

            renderOption={(props, option) => {
                const { key, ...rest } = props;

                return (
                    groupedBy ? (
                        option.name && (
                            <ul key={option.id} {...rest}>
                                <MenuItem value={option.id}
                                          sx={{
                                              display: 'block',
                                              '&:hover': { background: 'transparent' }
                                          }}
                                          className="preload-items overflow-hidden text-ellipsis"
                                >
                                    {option.name}
                                </MenuItem>
                            </ul>
                        )
                    ) : (
                        <ul key={option.id} {...rest}>
                            <li>{option.name}</li>
                        </ul>
                    )
                )
            }}

            // Styles
            ListboxProps={{
                ...groupedBy && ({
                    sx: { padding: "0" }
                })
            }}

            sx={{
                '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input' : { minWidth: 'unset' }
            }}
        />
    )
}
