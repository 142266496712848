import React, {useEffect, useState} from "react";

// Design
import {Box, RadioGroup, Typography} from "@mui/material";
import { motion } from "framer-motion";
import FormControl from "@mui/material/FormControl";

// Components
import SearchFilter from "../form/SearchFilter";
import {FormsTitle} from "../form/FormsTitle";
import {CardsLoader} from "../loaders/Loaders";
import CardItem from "../form/CardItem";
import {LoadMoreButton} from "../../../../components/button/LoadMoreButton";

// Services
import PPAService from "../../../../services/PPAService";
import {useMobile} from "../../../../hooks/useMobile";


export const PpaType = ({   searchList,
                            setSearchList,
                            handleDrawerOpen,
                            handleDrawerClose,
                            networkCode,
                            ppaType,
                            setPpaType,
                            setPpaTypeCode,
                            setDrawerDatas,
                            setGetStartedSwitch,
                            setNetworkId
                            }) => {

    // Data States
    const [ppaTypeData, setPpaTypeData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [defaultPpaTypeId, setDefaultPpaTypeId] = useState('');

    // Responsive Hook
    const isMobile = useMobile();

    const handleGetPpaTypeFromCode = async () => {

        try {
            const ppaService = new PPAService();
            const response = await ppaService.getPPANetworkFromCode(networkCode);
            const hydraMember = response["hydra:member"];
            setPpaTypeData(hydraMember);

            if (hydraMember.length !== 0) {

                // Network ID extraction - for location.state.networkId
                const extractedNetworkId = hydraMember[0]?.network?.id ?? 0;
                setNetworkId(extractedNetworkId)

                // isDefault Detection
                const defaultPpaType = hydraMember.filter((item) => item.isDefault);

                if(defaultPpaType.length !== 0) {
                    setPpaType(defaultPpaType[0].id);
                    setDefaultPpaTypeId(defaultPpaType[0].id);
                    setGetStartedSwitch(false)
                } else {
                    setPpaType('');
                    setGetStartedSwitch(true)
                }

                setIsLoading(false);

            } else {
                setIsLoading(false);
                setNoResult(true);
            }

        } catch (error) {
            console.error(error);
            if (error) {
                setIsLoading(false);
                setNoResult(true);
            }
        }
    }

    useEffect(() => {
        handleGetPpaTypeFromCode().then();
    }, [searchList]);

    // Change URL Management
    useEffect(() => {
        if(networkCode) {
            window.history.replaceState(null, "Choose your type of ProjectProposal submission", `/ppa/${networkCode}`)
        }
    }, [networkCode]);

    // isDefault Verification
    useEffect(() => {
        defaultPpaTypeId && fillDrawer(defaultPpaTypeId)

        // Close for Mobile View
        isMobile && handleDrawerClose()

        // Close for Desktop View
        setTimeout(() => {
            if(!isMobile) {
                handleDrawerOpen()
            }
        }, 1000)
    }, [defaultPpaTypeId]);

    // Search States
    const [searchQuery, setSearchQuery] = useState('');

    // More Items States
    const itemsPerRow = 8;
    const [nextItems, setNext] = useState(itemsPerRow);
    const [noMoreToLoad, setNoMoreToLoad] = useState(false);
    const [noResult, setNoResult] = useState(false);

    const fillDrawer = (value) => {

        setIsLoading(true)

        const ppaTypeDataContent = ppaTypeData.filter((type) => type.id === Number(value))
        setDrawerDatas(ppaTypeDataContent[0])
        setPpaTypeCode(ppaTypeDataContent[0].code)

        setIsLoading(false)

    }

    const handleChange = (event) => {
        const radioValue = event.target.value
        setPpaType(radioValue);
        setGetStartedSwitch(false)
        fillDrawer(radioValue);
    };

    return (
        <motion.article
            className="pt-[10px]"
            initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            transition={{ duration:.5, delay: .3}}
        >
            <div className="flex flex-wrap items-center">

                <FormsTitle title={<>Choose your type of<br/><span>project submission</span></>}/>

                {/* Filter Research */}
                <SearchFilter networkData={ppaTypeData}
                              setSearchQuery={setSearchQuery}
                              setSearchList={setSearchList}
                              setNoMoreToLoad={setNoMoreToLoad}
                              setNoResult={setNoResult}
                              itemsPerRow={itemsPerRow}
                />

                <Box sx={{ flexGrow: 1, margin: '20px 0' }}>
                    {isLoading && ( <CardsLoader itemsPerRow={itemsPerRow} />)}
                    <FormControl className="w-full">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="ppatype"
                            value={ppaType}
                            onChange={handleChange}
                        >
                            {/* Cards */}
                            <CardItem searchQuery={searchQuery}
                                      networkData={ppaTypeData}
                                      searchList={searchList}
                                      nextItems={nextItems}
                                      handleDrawerOpen={handleDrawerOpen}
                            />
                        </RadioGroup>
                    </FormControl>

                    {/* More Network */}
                    {(nextItems < ppaTypeData?.length && !noMoreToLoad ) && (
                        <LoadMoreButton title={"Load More"}
                                        nextItems={nextItems}
                                        itemsPerRow={itemsPerRow}
                                        setNext={setNext}
                                        roundedborder
                        />
                    )}

                    {/* No Results */}
                    {noResult && (
                        <Typography component={"div"}
                                    variant="subtitle1"
                                    sx={{
                                        marginBottom: "3rem",
                                        width: "100%",
                                        textAlign: 'center',
                                    }}
                        >
                            No PPA type found
                        </Typography>
                    )}
                </Box>

            </div>
        </motion.article>
    )
}
