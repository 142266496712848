import {SectionComponent} from "../../components/SectionComponent";
import FaqImage from "../../assets/images/faqbg.webp";

export const Faq = () => {
    return (
        <SectionComponent
            title={infos.title}
            titleColor={ infos.titleColor}
            description={infos.description}
            image={infos.image}
            faq
            borderBottom
        />
    )
}

const infos = {
    title: "Frequently Asked",
    titleColor: "Questions",
    description: "Find quick answers to common questions about using ISIA, from ProjectProposal submission to resource management.",
    image: FaqImage
}