import React, {createContext, memo, useEffect, useState} from "react";

// Design
import {Grid, Typography, ListItem, Accordion, AccordionSummary} from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import { BiSolidPlusCircle } from "react-icons/bi";
import { BiSolidMinusCircle } from "react-icons/bi";
import {AnimatePresence, motion} from "framer-motion";
import Alert from "@mui/material/Alert";

// Components
import {FormContentInputs} from "./FormContentInputs";

// List Type Form
import {ListTypeFormsContainer} from "./items/listtypeform/ListTypeFormsContainer";
import {ListTypeFormsIcon} from "./items/listtypeform/ListTypeFormsIcon";

// Context
export const FormContentFormsContext = createContext(null);


export const FormContentForms = memo(function FormContentForms({ joinedInputs,
                                     joinedForms,
                                     selectOptions,
                                     preloadServices,
                                     values,
                                     activeTabId,
                                     activeStep,
                                     setMissingFieldsChanges,
                                     hideMissingFields,

                                     setJoinedInputs,
                                     setState,
                                     isRead,
                                 }) {

    // console.log(joinedForms);

    /***** Accordion Management ****/
    const [expanded, setExpanded] = useState([]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? [...expanded, panel] : expanded.filter((p) => p !== panel));
    };


    /***** Unfilled Inputs Management ****/
    const unfilledInputs = document.getElementsByClassName('no-value');
    const unfilledInputsLength = unfilledInputs ? unfilledInputs.length : 0;

    // Counter Live Update
    const [reloadMissingFieldsChecking, setReloadMissingFieldsChecking] = useState(false);

    useEffect(() => {
        setMissingFieldsChanges(unfilledInputsLength)
    // }, [unfilledInputsLength]);
    }, [reloadMissingFieldsChecking]);


    const handleUnfilledInputs = () => {

        // Create new array to push empty input ids of unfilledInputs
        const noValueInput = []

        for (let input of unfilledInputs) {
            // noValueInput.push(Number(input.id))
            noValueInput.push(Number(input.dataset.inputid))
        }

        // Call all inputs ids of entire form
        const listInputsIds = joinedForms.map((item) => item.listInputs.map((list) => list.id))

        // Return a new array with same structure as listInputsIds but with empty fields IDs ONLY
        const noValueInputIds = listInputsIds.map((list) => list.filter((el) => noValueInput.includes(el)))

        // Get forms IDs to set array length, of unfilled IDs, as value for <input type="hidden">
        for (let [index, form] of joinedForms.entries()) {

            const hiddenUnfilledCount = document.getElementById(`hidden_${form.id}`)

            if(noValueInputIds[index].length > 0) {
                hiddenUnfilledCount.value = noValueInputIds[index].length;
            }
        }
    }

    useEffect(() => {
        // activeStep > 0 && handleUnfilledInputs();
        handleUnfilledInputs();
    });


    /***** Unfilled Forms Management ****/
    const [unfilledForms, setUnfilledForms] = useState([]);

    const handleUnfilledForms = () => {

        const formsIdsWithMissingFields = [];

        for (let form of joinedForms) {
            const hiddenUnfilledCount = document.getElementById(`hidden_${form.id}`)

            if (hiddenUnfilledCount.value > 0) {
                formsIdsWithMissingFields.push({ id: Number(hiddenUnfilledCount.id.split('_').pop()), total: Number(hiddenUnfilledCount.value) });
            }
        }

        setUnfilledForms(formsIdsWithMissingFields)
    }

    useEffect(() => {
        handleUnfilledForms();
    }, [unfilledInputsLength]);


    return (
        <>
            {joinedForms.map((form, formindex) => (
                <React.Fragment key={formindex}>

                    {/* FORMS PER TAB : FILTER */}
                    <ListItem className={`${(form.tabId ? (activeTabId === form.tabId) : (activeTabId === form.tab.id)) ? 'displaying' : 'hidden'}`}>

                        <Accordion sx={{background: 'transparent'}}
                                   className="accordion"
                                   expanded={expanded.includes(`panel${formindex}`)}
                                   onChange={handleChange(`panel${formindex}`)}

                                   slotProps={{ transition: { timeout: 400 } }}
                        >
                            <AccordionSummary
                                expandIcon={expanded.includes(`panel${formindex}`) ? <BiSolidMinusCircle/> :
                                    <BiSolidPlusCircle/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={`accordion-summary hover:bg-gray-200`}
                            >
                                {(form.hasOwnProperty('typeOfForm') && form.typeOfForm !== null) && (
                                    <ListTypeFormsIcon />
                                )}

                                <Typography variant="regular">
                                    <div dangerouslySetInnerHTML={{__html: form.name}} className={"form-title"}/>
                                </Typography>

                                <div className="error-alert relative ml-auto mr-2 lg:mr-4">
                                    <AnimatePresence>
                                        {(unfilledForms.find((found) => found.id === form.id) && !hideMissingFields) && (
                                            <motion.div
                                                initial={{ x: 0, opacity: 0}}
                                                animate={{ x: 0, opacity: 1}}
                                                transition={{duration: .3}}
                                                exit={{x: 0, opacity: 0}}
                                            >
                                                <Alert severity="error"
                                                       variant="filled"
                                                       sx={{ width: '34px',
                                                            height: '34px',
                                                            padding: '2px',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            '& .MuiAlert-icon': {
                                                                padding: '0',
                                                                margin: '0'
                                                            }
                                                       }}
                                                />
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>

                            </AccordionSummary>
                            <AccordionDetails sx={{padding: { xs:'25px 0 60px 16px', lg: '25px 0 60px 22px'}}}>

                                <FormContentFormsContext.Provider value={{ reloadMissingFieldsChecking, setReloadMissingFieldsChecking }}>
                                    {(form.hasOwnProperty('typeOfForm') && form.typeOfForm !== null) ? (

                                    // LIST Type Forms Management
                                    <ListTypeFormsContainer form={form}
                                                            joinedInputs={joinedInputs}
                                                            selectOptions={selectOptions}
                                                            preloadServices={preloadServices}
                                                            values={values}

                                                            setJoinedInputs={setJoinedInputs}
                                                            setState={setState}
                                                            isRead={isRead || form.isLocked}

                                    />

                                ) : (
                                    <Grid container rowSpacing={3} columnSpacing={{xs: 2, lg: 4}} sx={{margin: '0 0 0px'}}>
                                        {/* BASIC Type Forms Management*/}
                                        {form.listInputs.map((listinput, index) => (
                                            <React.Fragment key={index}>


                                                {joinedInputs.map((input, index) => (
                                                    <React.Fragment key={index}>

                                                        {/* INPUTS PER FORM : FILTER */}
                                                        {(input.id === listinput.id) && (

                                                            <FormContentInputs inputData={input}
                                                                               inputIndex={index}
                                                                               selectOptions={selectOptions}
                                                                               formId={form.id}
                                                                               preloadServices={preloadServices}
                                                                               values={values}
                                                                               isRead={isRead || form.isLocked}

                                                            />

                                                        )}

                                                    </React.Fragment>
                                                ))}

                                                </React.Fragment>
                                            ))}
                                        </Grid>
                                    )}
                                </FormContentFormsContext.Provider>

                            </AccordionDetails>
                        </Accordion>
                    </ListItem>

                </React.Fragment>
            ))}

            {/* Unfilled Inputs Counters */}
            {joinedForms.map((form, index) => (
                <input key={index} type="hidden" value={0} id={`hidden_${form.id}`} className={`${form.tabId ? `hidden_${form.tabId}` : `hidden_${form.tab.id}`}`}/>
            ))}

            {/* Unfilled Inputs Total */}
            <input type="hidden" value={unfilledInputs.length} id="unfilled_total" />
        </>
    )
});
