export const Input = ( { register, errors, watch, type, name,label, id, htmlFor, placeHolder, required=false,autofocus  }) => {

    return (
        <div className="flex flex-col gap-2 w-full grow">
            <label htmlFor={htmlFor} className="block text-sm text-slate-500">{label} {required &&
                <sup className={`text-xs text-red-500`}>*</sup>}</label>
            <input type={type}
                   id={id}
                   placeholder={placeHolder}
                   {...register(name, {required: required && "This field is required" })}
                   autoFocus={autofocus}
                   className="border border-slate-300 border-solid rounded-sm w-full grow h-8 px-2 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
            { errors[name] && !watch(name) && (
                <span className={"block text-xs text-red-500/70"}
                      role="alert">{errors[name]?.message}</span>
            )}
        </div>
    )
}